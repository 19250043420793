import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public user: any;

  constructor(
    private _auth: AngularFireAuth,
    public _general: GeneralService
  ) {}

  public login(email: string, password: string): Promise<any> {
    return this._auth.signInWithEmailAndPassword(email, password);
  }

  public async register(email: string, password: string): Promise<any> {
    const user = await this._auth.createUserWithEmailAndPassword(
      email,
      password
    );
    return user;
  }

  public resetPassword(email: string): Promise<any> {
    return this._auth.sendPasswordResetEmail(email);
  }

  public loginGoogle(): Promise<any> {
    return this._auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  public async signOut(): Promise<any> {
    await this._auth.signOut();
  }

  public async sendVerificationEmail(): Promise<void> {
    let user = await this._auth.currentUser;
    if (!user) {
      return;
    }
    user.sendEmailVerification();
    this.signOut();
  }

  public async getUserAuth(force?: boolean): Promise<any> {
    if (this.user && !force) {
      return this.user;
    } else {
      const userAuth = await this.userSubscribeToPromise();
      if (!userAuth) {
        this._general.openRouter('login');
        //throw 'Usuario sin authentificacion';
      }
      this.user = userAuth;
      return userAuth;
    }
  }

  private async userSubscribeToPromise(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._auth.user.subscribe(
        (userAuth: any) => {
          resolve(userAuth || null); // resuelve con null si userAuth es falsy
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  public async updateEmail(newEmail: string): Promise<void> {
    try {
      const user = await this.getUserAuth();
      await user.updateEmail(newEmail);
      await user.reload(); // Actualiza los datos de autenticación en el cliente
      this.user.email = newEmail;
    } catch (error) {
      console.error(error);
    }
  }

  public async updatePassword(newPassword: string): Promise<void> {
    try {
      const user = await this.getUserAuth();
      await user.updatePassword(newPassword);
      await user.getIdToken(true); // Obtiene un nuevo token actualizado
    } catch (error) {
      console.error(error);
    }
  }
}
