// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: any = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBrh0w2jiMTfXawWQuCuN1dk09XlkX-gw8",
    authDomain: "nuup-nomads-web.firebaseapp.com",
    projectId: "nuup-nomads-web",
    storageBucket: "nuup-nomads-web.appspot.com",
    messagingSenderId: "776263577627",
    appId: "1:776263577627:web:457e31d8e796f7a8ec08be",
    measurementId: "G-8ER43W26YJ"
  },
  api: 'https://us-central1-nuup-nomads-web.cloudfunctions.net/api/',
  stripePK:
    'pk_test_51Kv49BGpg6abHyXQztVB4FcB0UCny0jDdmiT1fL2kyj5ztDn3oiI54Z6gTCIf0jo8qGoAepShFjUvSOSGNYq41YX006qI8Grb7',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
