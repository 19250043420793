import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user!: any;

  private resolvedUser: any; // Variable para almacenar el usuario resuelto

  constructor(public _firebase: FirebaseService) {}

  public async addUser(user: any, id: string): Promise<void> {
    await this._firebase.addDocumentWithID('users', user, id);
  }

  public async updateUser(user: any, id: string): Promise<void> {
    await this._firebase.updateDocument('users', user, id);
  }
 
  public async getUser(id: string): Promise<any> {
    const res = await this._firebase.getDocument('users', id);
    const data = {
      ...res.data(),
      id: res.id,
    };
    this.user = data;
    return this.user;
  }

   // Metodo para obtener el usuario resuelto
   public getUserFromResolver(): any {
    return this.resolvedUser;
  }

  // Metodo para asignar el usuario resuelto
  public setUserFromResolver(user: any): void {
    this.resolvedUser = user;
  } 

  public async getUserPaymentMethod(userId: any): Promise<any[]> {
    try {
      const userDoc = await this._firebase.getDocument('users', userId);
      if (!userDoc.exists) {
        throw new Error('User document not found');
      }

      const user = userDoc.data();
      const userCustomer = user.customerId;

      if (!userCustomer) {
        console.warn('User does not have a customer ID');
        return [];
      }

      const collectionRef = this._firebase.db.collection('paymentMethods').ref;
      const query = collectionRef.where('customer', '==', userCustomer);
      const querySnapshot = await query.get();

      const paymentMethods: any[] = [];
      querySnapshot.forEach((doc: any) => {
        const paymentMethod = {
          id: doc.id,
          ...doc.data(),
        };
        paymentMethods.push(paymentMethod);
      });

      return paymentMethods;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getUserPayments(userId: any): Promise<any[]> {
    try {
      const userDoc = await this._firebase.getDocument('users', userId);
      if (!userDoc.exists) {
        throw new Error('User document not found');
      }

      const user = userDoc.data();
      const userCustomer = user.customerId;

      if (!userCustomer) {
        console.warn('User does not have a customer ID');
        return [];
      }

      const collectionRef = this._firebase.db.collection('payments').ref;
      const query = collectionRef.where('customer', '==', userCustomer);
      const querySnapshot = await query.get();

      const payments: any[] = [];
      querySnapshot.forEach((doc: any) => {
        const payment = {
          id: doc.id,
          ...doc.data(),
        };
        payments.push(payment);
      });

      return payments;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getUserReservations(userId: any): Promise<any[]> {
    try {
      const userDoc = await this._firebase.getDocument('users', userId);
      if (!userDoc.exists) {
        throw new Error('User document not found');
      }

      const user = userDoc.data();
      const userCustomer = user.customerId;

      if (!userCustomer) {
        console.warn('User does not have a customer ID');
        return [];
      }

      const collectionRef = this._firebase.db.collection('reservations').ref;
      const query = collectionRef.where('customer', '==', userCustomer);
      const querySnapshot = await query.get();

      const reservations: any[] = [];
      querySnapshot.forEach((doc: any) => {
        const reservation = {
          id: doc.id,
          ...doc.data(),
        };
        reservations.push(reservation);
      });

      return reservations;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
