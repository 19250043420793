import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(public db: AngularFirestore, private _auth: AngularFireAuth) {}

  public addDocumentWithID(
    collection: string,
    data: any,
    id: any
  ): Promise<any> {
    return this.db.collection(collection).doc(id).set(data);
  }
  
  addDocument(collection: string, data: any) {
    return this.db.collection(collection).add(data);
  }

  public getDocument(collection: any, id: any): Promise<any> {
    return this.db.collection(collection).doc(id).ref.get();
  }

  public updateDocument(
    collection: string,
    data: any,
    id: string
  ): Promise<void> {
    return this.db.collection(collection).doc(id).set(data, { merge: true });
  } 

  deleteDocument(collection: any, id: any) {
    return this.db.collection(collection).doc(id).delete();
  }

  getCollectionRef(collection: any) {
    return this.db.collection(collection).ref;
  }

  generateData(ref: any, data: any): Promise<any[]> {
    return new Promise((resolve) => {
      ref.onSnapshot((collection: any) => {
        collection.docChanges().forEach((document: any) => {
          const doc = document.doc.data();
          doc.id = document.doc.id;
          switch (document.type) {
            case 'added':
              data.push(doc);
              break;
            case 'modified':
              const modified = data.findIndex((dd: any) => dd.id === doc.id);
              if (modified >= 0) {
                data[modified] = doc;
              }
              break;
            case 'removed':
              const removed = data.findIndex((dd: any) => dd.id === doc.id);
              if (removed >= 0) {
                data.splice(removed, 1);
              }
              break;
            default:
              break;
          }
        });
        resolve(data);
      });
    });
  }
}
