import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { IntercomService } from '../services/intercom.service';

@Injectable()
export class GetUserResolver implements Resolve<any> {
  constructor(
    private _user: UserService,
    private _auth: AuthService,
    public intercomService: IntercomService
  ) {}

  async resolve(): Promise<Observable<any>> {
    const userAuth = await this._auth.getUserAuth();
    const user = await this._user.getUser(userAuth.uid);
    //this._user.setUserFromResolver(user);
    //this.intercomService.loadIntercom();
    //this.intercomService.bootIntercom(user);
    //this.intercomService.IntercomShuthdown();
    return user;
  }
}
