import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  constructor() {}

  loadIntercom() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://widget.intercom.io/widget/bl0k19bs';
    document.head.appendChild(script);
  }

  bootIntercom(user: any) {
    (window as any).Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'bl0k19bs',
      name: user.name, // Nombre completo del usuario
      email: user.email, // Correo electrónico del usuario
      created_at: Math.floor(user.creationDate / 1000), // Fecha de registro como marca de tiempo de Unix,
      lastname: user.lastname,
      country: user.country,
      gender: user.gender,
      birthday: user.birthday,
      weight: user.weight,
      weight_unit: user.weight_unit,
      feet: user.feet,
      inches: user.inches,
      chronicDiseases: user.chronicDiseases,
      allergies: user.allergies,
    });
  }

  IntercomShow(){
    (window as any).Intercom('update', {
      hide_default_launcher: false,
    });
  }

  IntercomShuthdown() {
    (window as any).Intercom('update', {
      hide_default_launcher: true,
    });
  }
}
