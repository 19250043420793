import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { lastValueFrom } from 'rxjs';
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  public termOrPrivacy!: string;

  public isShowCard!: string;
 
  constructor(
    private _router: Router,
    private _alertCtrl: AlertController,
    private _http: HttpClient
  ) {}

  public openRouter(url: string): void {
    this._router.navigate([url]);
  }

  public async showAlert(
    text: string,
    message: string,
    buttons: any[]
  ): Promise<any> {
    const alert = await this._alertCtrl.create({
      header: text,
      message: message,
      buttons: buttons,
    });
    alert.present();
    return alert.onDidDismiss();
  } 

  public async showSweetAlert(
    title: string,
    text: string,
    icon: any,
    timer?: number,
  ): Promise<any> {
    return await Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: false,
      showConfirmButton: false,
      confirmButtonColor: '#103158',
      backdrop: false,
      timer: 2000,
    });
  }
  
  public async swalTimer(
    position: SweetAlertPosition,
    icon: SweetAlertIcon,
    title: string,
    text: string,
    timer: number,
    showConfirmButton: boolean,
    showCancelButton: boolean
  ): Promise<any> {
    return await Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: '#103158',
      backdrop: false, 
    });
  }  

  public alertQuestion(title: string, text: string) {
    return this.swalTimer('center', 'question', title, text, 0, true, true);
  } 

  public alertError(title: string, text: string) {
    return this.swalTimer('center', 'error', title, text, 0, true, true);
  } 

}
